import React, { Component } from "react"
import styled from "@emotion/styled"
import { HeadingSecondary } from "../Styles/headers"
import { SectionHero } from "../Styles/text"
import { navigate } from "gatsby"
import { BtnOutline } from "../Styles/buttons"
import { AlertBtn } from "../Styles/buttons"
const Form = styled.form`
  height: 100vh;
  width: 90vw;
  max-width: ${props => props.theme.sizes.innerWidth};
  position: fixed;
  left: ${props => (props.status === "show" ? "0" : "-100%")};
  top: 0;
  max-width: 700px;
  background: ${props => props.theme.colors.grey500};
  padding: ${props => props.theme.pm.pm300};
  z-index: 5;
  transition: all 0.3s ease-in-out;
  box-shadow: 6px 0px 8px rgba(0, 0, 0, 0.15);
`

const Input = styled.input`
  padding: ${props => props.theme.pm.pm100};
  border: none;
  outline: none;
  border-radius: 3px;
  display: block;
  margin-bottom: ${props => props.theme.pm.pm300};
  background: none;
  font-size: ${props => props.theme.text.text300};
  color: ${props => props.theme.colors.grey100};
  border-bottom: 1px solid;
  width: 100%;
  max-width: 400px;
`

export default class SignUpForm extends Component {
  state = {
    name: "",
    email: "",
    wrongMail: "",
  }

  newSubscriber = e => {
    e.preventDefault()
    this.props.newSubscriber(this.state.name, this.state.email)
  }

  handleChange = e => {
    switch (e.target.id) {
      case "input-name":
        this.setState({ name: e.target.value })
        break
      case "input-email":
        this.setState({ email: e.target.value })
        break
      default:
        console.log("something went wrong")
    }
  }

  newSubscriber = e => {
    e.preventDefault()

    if (this.state.name && this.state.email) {
      const url = `/.netlify/functions/app?name=${this.state.name}&email=${this.state.email}`
      fetch(url)
        .then(res => res.json())
        .then(data => {
          if (data.error) {
            this.setState({ wrongMail: data.message, name: "", email: "" })
            setTimeout(_ => {
              this.setState({ wrongMail: "" })
            }, 2000)
          } else {
            this.setState({ name: "", email: "" })
            navigate("/caseStudy/")
          }
        })
        .catch(err => console.log(err))
    }
  }
  render() {
    return (
      <Form onSubmit={this.newSubscriber} status={this.props.status}>
        <HeadingSecondary color={"onDark"}>Hello, friend.</HeadingSecondary>
        <SectionHero color={"onDark"}>
          Signup below to the your free case study.
        </SectionHero>
        <Input
          type="text"
          placeholder="First name"
          id="input-name"
          value={this.state.name}
          onChange={this.handleChange}
        />
        <Input
          type="email"
          placeholder="Your email"
          value={this.state.email}
          id="input-email"
          onChange={this.handleChange}
        />
        {this.state.wrongMail !== "" ? (
          <button css={AlertBtn}>{this.state.wrongMail}</button>
        ) : (
          ""
        )}
        <button css={BtnOutline} type="submit">
          Sign Up
        </button>
      </Form>
    )
  }
}
