import React, { Component } from "react"
import { Global, css } from "@emotion/core"
import { ThemeProvider } from "emotion-theming"
import { theme } from "../Styles/theme"
import Layout from "../Components/layout"
import { HeadingSecondary } from "../Styles/headers"
import { BtnMedium } from "../Styles/buttons"
import { Section } from "../Styles/section"
import SignupForm from "../Components/form"
import { GlobalStyles } from "../Styles/global"

const FreeCourse = css`
  padding-top: ${theme.pm.pm400};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  max-width: ${theme.sizes.innerWidth};
  margin-bottom: ${theme.pm.pm400};
  @media only screen and (max-width: ${theme.breakpoints.mobile}) {
    padding-top: ${theme.pm.pm200};
  }
`

export default class signup extends Component {
  state = { popup: "hidden" }

  render() {
    return (
      <ThemeProvider theme={theme}>
        <Global styles={GlobalStyles} />
        <Layout>
          <div>
            <Section
              onClick={() =>
                this.state.popup === "show"
                  ? this.setState({ popup: "hide" })
                  : null
              }
              css={FreeCourse}
            >
              <HeadingSecondary>
                How to get into your dream college masterclass
                {" "}
              </HeadingSecondary>


              <BtnMedium onClick={() => this.setState({ popup: "show" })}>
                Get Your Case Study Now{" "}
              </BtnMedium>
            </Section>
            <SignupForm status={this.state.popup} />
          </div>
        </Layout>
      </ThemeProvider>
    )
  }
}
